import {
  FormControl as BaseFormControl,
  FormControlProps,
  FormControlOverrides
} from 'baseui/form-control'
import { withOverrides } from './overrides'

const globalOverrides: FormControlOverrides = {
  Label: {
    style: {
      fontSize: '18px',
      lineHeight: 'normal'
    }
  }
}

export const FormControl = withOverrides<FormControlProps>(BaseFormControl, globalOverrides)

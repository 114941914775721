import React from 'react'
import { Layout, UserContext } from '../components/Layout'
import { Button } from '../components/Button'
import { Input } from '../components/Input'
import { Checkbox } from '../components/Checkbox'
import { FormControl } from '../components/FormControl'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { LABEL_PLACEMENT } from 'baseui/checkbox'
import { HeadingLevel } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { Notification } from '../components/Notification'
import { passwordResetConfirm, LoginResponse, PasswordResetErrors } from '../api/user'
import constants from '../constants'
import { getQueryVariable } from '../utils'

interface ErrorMessageProps {
  hasError: boolean
}
const PasswordResetConfirmErrorMessage = ({ hasError }: ErrorMessageProps) => {
  if (hasError) {
    return (
      <Notification data-="error-message" kind="negative">
        There was an issue resetting password - please try again. <br />
        If the problem persists, please contact {constants.supportEmail} for help.
      </Notification>
    )
  }
  return null
}
interface PasswordResetFormProps {
  uid: string
  resetToken: string
}
const PasswordResetForm = ({ uid, resetToken }: PasswordResetFormProps) => {
  const [password, setPassword] = React.useState('')
  const [passwordRepeat, setpasswordRepeat] = React.useState('')
  const [remember, setRemember] = React.useState(true)
  const [confirming, setConfirming] = React.useState(false)
  const [passwordConfirmError, setPasswordConfirmError] = React.useState(false)
  const [errorReasons, setErrorReasons] = React.useState<PasswordResetErrors | null>(null)
  const { setUserContext } = React.useContext(UserContext)

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault()

    if (setUserContext) {
      const successFunction = (passwordResetConfirmResponse: LoginResponse) => {
        setUserContext(passwordResetConfirmResponse, remember)
        setConfirming(false)
        navigate('/')
      }
      const failedFunction = (reasons: PasswordResetErrors) => {
        setConfirming(false)
        setPasswordConfirmError(true)
        setErrorReasons(reasons)
      }
      setConfirming(true)
      passwordResetConfirm(
        uid,
        resetToken,
        password,
        passwordRepeat,
        successFunction,
        failedFunction
      )
    }

    return false
  }

  let password1Error = null
  let password2Error = null
  if (errorReasons) {
    password1Error = errorReasons.new_password1 ? errorReasons.new_password1.join(' ') : null
    password2Error = errorReasons.new_password2 ? errorReasons.new_password2.join(' ') : null
  }

  return (
    <form onSubmit={submitForm}>
      <PasswordResetConfirmErrorMessage hasError={passwordConfirmError} />
      <FormControl label="Password" caption={() => ''} error={password1Error}>
        <Input
          id="password"
          value={password}
          onChange={e => setPassword(e.currentTarget.value)}
          type="password"
          clearable={true}
        />
      </FormControl>
      <FormControl label="Retype password" caption={() => ''} error={password2Error}>
        <Input
          id="passwordRepeat"
          value={passwordRepeat}
          onChange={e => setpasswordRepeat(e.currentTarget.value)}
          type="password"
          clearable={true}
        />
      </FormControl>
      <FormControl>
        <Checkbox
          checked={remember}
          onChange={e => setRemember(e.currentTarget.checked)}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          Remember me
        </Checkbox>
      </FormControl>
      <Button
        kind="secondary"
        isLoading={confirming}
        onClick={submitForm}
        data-testid={!confirming ? 'password-confirm-button' : 'password-confirm-button-loading'}
      >
        Save changes
      </Button>
    </form>
  )
}

interface PasswordResetPageProps {
  location: WindowLocation
}
export const PasswordResetPage = (props: PasswordResetPageProps) => {
  const resetToken = getQueryVariable('token', props.location.search)
  const uid = getQueryVariable('uid', props.location.search)

  let formElement = null
  let headingText = ''
  if (uid && resetToken) {
    headingText = 'Set your password'
    formElement = (
      <Grid>
        <Cell span={[4, 6]} align={ALIGNMENT.start}>
          <PasswordResetForm uid={uid} resetToken={resetToken} />
        </Cell>
      </Grid>
    )
  } else {
    headingText = 'This link is invalid.'
  }

  return (
    <>
      <Grid>
        <Cell span={[4, 6]} align={ALIGNMENT.start}>
          <HeadingLevel>
            <Heading>{headingText}</Heading>
          </HeadingLevel>
        </Cell>
      </Grid>
      {formElement}
    </>
  )
}
interface LayoutPasswordResetPageProps {
  location: WindowLocation
}
const LayoutPasswordResetPage = (props: LayoutPasswordResetPageProps) => {
  return (
    <Layout showNav={false} searchBarAvailable={false} backButton={false}>
      <PasswordResetPage location={props.location} />
    </Layout>
  )
}

export default LayoutPasswordResetPage
